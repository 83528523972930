import React from "react"
import YouTube from "react-youtube"
import PropTypes from "prop-types"

import "./video.css"


class Video extends React.Component {
  render() {
    if (typeof this.props.youtubeId === "undefined") {
      return null
    }
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        modestbranding: 1,
        rel: 0
      },
    }

    return (
      <div className="video-wrapper">
        <YouTube
          videoId={this.props.youtubeId}
          opts={opts}
          onReady={this._onReady}
        />
      </div>
    )
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo()
  }
}

Video.propTypes = {
  videoId: PropTypes.string,
}

export default Video
