import React from "react"
import { graphql } from "gatsby"
import PostLayout from "../components/post_layout"
import Nutrition from "../components/nutrition"
import Video from "../components/video"
import { MDXRenderer } from "gatsby-plugin-mdx"
import ReactLinkify from "react-linkify"
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image"

const Post = ({ data }) => {
  const post = data.mdx
  const nutrition = {
    serving_size: post.frontmatter.serving_size,
    calories: post.frontmatter.calories,
    fat: post.frontmatter.fat,
    protein: post.frontmatter.protein,
    carbohydrates: post.frontmatter.carbohydrates,
  }
  const youtubeId = post.fields.youtubeId;

  let lineCount = 0;

  return (
    <PostLayout>
      <Seo
        title={post.frontmatter.title} 
        meta={[{name: "keywords", content: post.frontmatter.keywords}]}
        image={getImage(post.fields.thumbnail)}
        description={"Watch Heather make " + post.frontmatter.title + " from " + post.frontmatter.source}
      />
      <div>
        <h1>{post.frontmatter.title}</h1>
        <h2>{post.frontmatter.source}</h2>
      </div>
      <Video youtubeId={youtubeId} />
      <div>
        {post.frontmatter.description && 
          post.frontmatter.description.split('\n').map((line) => {
            lineCount++;
            return (<p key={lineCount}>  
              <ReactLinkify>{line}</ReactLinkify>
            </p>)
          }) 
        }
      </div>
      <Nutrition data={nutrition} />
      <div>
        <MDXRenderer>{post.body}</MDXRenderer>
      </div>
    </PostLayout>
  )
}
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        youtubeId
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH)
          }
        }
      }
      frontmatter {
        title
        video_url
        source
        calories
        carbohydrates
        date(formatString: "MMMM D, Y")
        difficulty
        fat
        protein
        serving_size
        description
        keywords
      }
    }
  }
`

export default Post;
