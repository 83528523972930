import PropTypes from "prop-types"
import React from "react"

const Nutrition = ({ data }) => {
  if (data.serving_size == null || typeof data.serving_size === "undefined") {
    return null
  } else {
    return (
      <table>
        <thead>
          <tr>
            <th colSpan="2">Nutrition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Serving Size</td>
            <td>{data.serving_size}</td>
          </tr>
          <tr>
            <td>Calories</td>
            <td>{data.calories}</td>
          </tr>
          <tr>
            <td>Fat</td>
            <td>{data.fat}</td>
          </tr>
          <tr>
            <td>Protein</td>
            <td>{data.protein}</td>
          </tr>
          <tr>
            <td>Carbohydrates</td>
            <td>{data.carbohydrates}</td>
          </tr>
        </tbody>
      </table>
    )
  }
}

Nutrition.propTypes = {
  data: PropTypes.object,
}

Nutrition.defaultProps = {
  data: {},
}

export default Nutrition
